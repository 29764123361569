import * as React from 'react'
import {nucleobaseConfig} from 'config'

import {
  NucleobaseConfigContextProvider,
  NucleobaseEngineContext,
  NucleobaseEngineContextProps,
  useContentfulEngine,
} from '@invitae/nucleobase'

const engine: NucleobaseEngineContextProps = {
  useEngine: useContentfulEngine,
}

const BaseTestCatalogContext: React.FC<React.PropsWithChildren> = ({children}) => {
  return (
    <NucleobaseConfigContextProvider value={nucleobaseConfig}>
      <NucleobaseEngineContext.Provider value={engine}>{children}</NucleobaseEngineContext.Provider>
    </NucleobaseConfigContextProvider>
  )
}

export default BaseTestCatalogContext
